import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdminFullDataPayload } from '../../core/model/admin.model';
import { ProfessionalFullDataPayload } from '../../core/model/professional.model';
import { Role } from '../enums/role';
import { LoginResponse, LoginUser } from '../interfaces/login.interfaces';
import { UserJwtPayload } from '../interfaces/user-jwt';
import { LocalStorageService } from './local-storage.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn$ = new BehaviorSubject<boolean>(false);
  private userSubject$: BehaviorSubject<UserJwtPayload | null> =
    new BehaviorSubject<UserJwtPayload | null>(null);

  #router = inject(Router);
  #http = inject(HttpClient);
  #localStorage = inject(LocalStorageService);
  #jwtHelper = new JwtHelperService();
  #logger = inject(LoggerService);
  #messageService = inject(MessageService);

  get user(): Observable<UserJwtPayload | null> {
    return this.userSubject$.asObservable();
  }

  get userSnapshot(): UserJwtPayload | null {
    return this.userSubject$.getValue();
  }

  get isLoggedIn() {
    return this.loggedIn$.asObservable();
  }

  get isProfessional() {
    return this.userSnapshot?.role === Role.PROFESSIONAL;
  }

  get isOrganization() {
    return this.userSnapshot?.role === Role.ADMIN;
  }

  login(user: LoginUser): Observable<any> {
    return this.#http.post<LoginResponse>(
      `${environment.shoppinggooUrl}/auth/authenticate`,
      user
    );
  }

  /**
   * Verifica se o token jwt é válido e carrega os dados do usuário
   * @param jwtToken token jwt
   * @returns Observable<boolean> indica se o login foi bem-sucedido
   */
  loadUserJwt(jwtToken: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this.isLoggedIn && this.userSnapshot) {
        this.#logger.info(
          'AuthService: ',
          'User is already logged in\n',
          this.userSnapshot
        );
        observer.next(true);
        observer.complete();
        return;
      }

      // Verifica se o token jwt existe
      if (jwtToken) {
        // Verifica se o token jwt não está expirado
        if (!this.#jwtHelper.isTokenExpired(jwtToken)) {
          // Decodifica o token jwt
          const decodedToken: UserJwtPayload =
            this.#jwtHelper.decodeToken(jwtToken)!;

          // Busca os dados completos do usuário
          this.getUserFullDataforEmail(decodedToken).subscribe({
            // Se os dados forem carregados com sucesso, atualiza o usuário e indica que o Carregamento dos dados foi bem-sucedido
            next: (data) => {
              decodedToken.userFullData = data;
              // Token válido, atualiza o usuário e indica que o login foi bem-sucedido
              this.userSubject$.next(decodedToken);
              this.loggedIn$.next(true);
              this.#logger.info('AuthService: ', 'Get User Full Data\n', data);
              observer.next(true); // Carregamento dos dados bem-sucedido
              observer.complete();
            },
            // Se houver um erro, indica que o Carregamento dos dados falhou e limpa as informações do usuário
            error: (error) => {
              this.#logger.error(
                'AuthService: ',
                'Get User Full Data\n',
                error
              );
              this.logout();
              observer.next(false); // Falha ao carregar os dados do usuário
              observer.complete();
            },
          });
        } else {
          // Se o token jwt estiver expirado, indica que o Carregamento dos dados falhou
          this.#logger.error('AuthService: ', 'Token expired\n');
          this.logout();
          observer.next(false); // Token expirado
          observer.complete();
          this.#messageService.add({
            key: 'toast-feedback-request',
            severity: 'error',
            summary: 'Erro',
            detail: 'Token expirado. Faça o login novamente.',
          });
        }
      } else {
        // Se o token jwt não existir, indica que o Carregamento dos dados falhou
        this.#logger.error('AuthService: ', 'Token not found\n');
        this.logout();
        observer.next(false); // Token não encontrado
        observer.complete();
      }
    });
  }

  loginLoadUserJwt(
    jwtToken: string,
    isAdmin: boolean,
    rememberme: boolean
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!jwtToken) {
        // Token ausente
        this.handleInvalidLogin();
        resolve(false);
        return;
      }

      this.#localStorage.addAuthToken(jwtToken, rememberme);
      const decodedToken: UserJwtPayload =
        this.#jwtHelper.decodeToken(jwtToken)!;
      const loadIsAdmin = decodedToken.role === Role.ADMIN;

      // Verifica se o tipo de usuário corresponde ao esperado (Admin ou não)
      if (loadIsAdmin !== isAdmin) {
        // Permissão inválida
        this.handleInvalidLogin();
        resolve(false);
        return;
      }

      // Busca os dados completos do usuário
      firstValueFrom(this.getUserFullDataforEmail(decodedToken))
        .then((data) => {
          decodedToken.userFullData = data;
          this.setUserSession(decodedToken, true);
          resolve(true); // Login bem-sucedido
        })
        .catch((error) => {
          this.handleInvalidLogin(error);
          resolve(false); // Falha no login
        });
    });
  }

  private handleInvalidLogin(error?: any): void {
    // Lida com logins inválidos ou erros
    this.loggedIn$.next(false);
    this.userSubject$.next(null);
    this.#localStorage.clearStorage();

    if (error) {
      this.#logger.error('AuthService: ', 'Get User Full Data\n', error);
    }
  }

  private setUserSession(
    decodedToken: UserJwtPayload,
    isLoggedIn: boolean
  ): void {
    // Configura sessão do usuário
    this.userSubject$.next(decodedToken);
    this.loggedIn$.next(isLoggedIn);
    this.#logger.info('AuthService: ', 'User session set\n', decodedToken);
  }

  getUserFullDataforEmail(
    userJwt: UserJwtPayload
  ): Observable<ProfessionalFullDataPayload | AdminFullDataPayload | null> {
    if (userJwt.role === Role.PROFESSIONAL) {
      const params = new HttpParams().set('email', userJwt.email);
      return this.#http.get<ProfessionalFullDataPayload>(
        `${environment.organizationgooUrl}/staff/email`,
        { params }
      );
    } else if (userJwt.role === Role.ADMIN) {
      const params = new HttpParams().set('email', userJwt.email);
      return this.#http.get<any>(`${environment.globalgooUrl}/user/email`, {
        params,
      });
    }
    return of(null);
  }

  getUserFullData(): Observable<
    ProfessionalFullDataPayload | AdminFullDataPayload | null
  > {
    if (this.userSnapshot?.role === Role.PROFESSIONAL) {
      const params = new HttpParams().set('email', this.userSnapshot!.email);
      return this.#http.get<ProfessionalFullDataPayload>(
        `${environment.organizationgooUrl}/staff/email`,
        { params }
      );
    } else if (this.userSnapshot?.role === Role.ADMIN) {
      const params = new HttpParams().set('email', this.userSnapshot!.email);
      return this.#http.get<any>(`${environment.globalgooUrl}/user/email`, {
        params,
      });
    }
    return of(null);
  }

  logout() {
    try {
      // Limpar status de autenticação
      this.loggedIn$.next(false);
      this.userSubject$.next(null);

      // Limpar o armazenamento local
      this.#localStorage.clearStorage();

      // Navegar para a página de login após limpar dados
      this.#router.navigate(['/login']);
      this.#logger.info('AuthService: ', 'Logout\n');
    } catch (error) {
      // Log do erro
      this.#logger.error('AuthService:', 'Logout\n', error);
    }
  }

  refreshToken() {
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) {
      return of();
    }
    return this.#http.get<LoginResponse>(
      `${environment.shoppinggooUrl}/auth/refresh`
    );
  }

  hasAnyRole(allowedRoles: Array<Role>): boolean {
    const user = this.userSubject$.getValue();
    if (!user || !user.role) {
      return false;
    }

    return allowedRoles.includes(user.role as Role);
  }
}
