import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {


  transform(value: string, format: string = 'dd/MM/yyyy HH:mm:ss'): string | null {
    if (!value) return null;

    try {
      // Divide a string em data e hora
      const [datePart, timePart] = value.split(' ');
      const [day, month, year] = datePart.split('/');

      // Converte para o formato ISO 8601
      const isoDate = `${year}-${month}-${day}T${timePart}`;
      const date = new Date(isoDate);

      if (isNaN(date.getTime())) {
        throw new Error('Invalid Date');
      }

      // Formata a data usando o formato customizado
      return this.formatDate(date, format);

    } catch (error) {
      console.error('Erro ao formatar data:', error);
      return null;
    }
  }

  private formatDate(date: Date, format: string): string {
    const map: { [key: string]: string } = {
      'dd': this.pad(date.getDate()),
      'MM': this.pad(date.getMonth() + 1),
      'yyyy': date.getFullYear().toString(),
      'HH': this.pad(date.getHours()),
      'mm': this.pad(date.getMinutes()),
      'ss': this.pad(date.getSeconds()),
    };

    // Substitui os tokens no formato pela data correspondente
    return format.replace(/dd|MM|yyyy|HH|mm|ss/g, (match) => map[match]);
  }

  private pad(value: number): string {
    return value.toString().padStart(2, '0'); // Garante dois dígitos
  }
}
