import { ApplicationConfig, importProvidersFrom, inject } from '@angular/core';
import { provideRouter } from '@angular/router';

import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ConfirmationService, MessageService } from 'primeng/api';
import { routes } from './app.routes';
import { authTokenInterceptor } from './core/interceptors/auth-token.interceptor';
import { LocalStorageService } from './shared/services/local-storage.service';

export function tokenGetter() {
  const storageService = inject(LocalStorageService);
  return storageService.getAuthToken();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes /*withDebugTracing()*/),
    provideHttpClient(withInterceptors([authTokenInterceptor]), withFetch()),
    provideAnimations(),
    provideEnvironmentNgxMask(),
    importProvidersFrom([
      JwtModule.forRoot({ config: { tokenGetter: tokenGetter } }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    MessageService,
    ConfirmationService,
  ],
};

// TODO AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
